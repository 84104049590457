.Applications {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Applications h2 {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.Applications input[type="text"] {
  margin-top: 10px;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #2e7d32;
}

.Applications button {
  padding: 8px 15px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 25px;
  transition: background-color 0.3s;
}

.Applications label {
  margin-top: 2px;
  padding: 5px;
}
.Applications #application-details {
  padding: 5px;
  flex-direction: row;
  justify-content: center;
}
.Applications #accept {
  background-color: #2e7d32;
}

.Applications #reject {
  background-color: gray;
}

.Applications #buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
