body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure the page fills at least the viewport height */
}

h1 {
    margin-top: 20px;
    color: #2E7D32; /* Green color for the Welcome message */
}

#upload-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#upload-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #2E7D32; /* Green color for the upload button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: none;
}

/* New class for making the upload button visible */
#upload-button.visible {
    display: block;
}

#file-input {
    margin-bottom: 10px;
}

