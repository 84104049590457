.unauthorized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
}

.unauthorized-container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.unauthorized-container p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.unauthorized-actions .btn {
  display: inline-block;
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.unauthorized-actions .btn:hover {
  background-color: #0056b3;
}
