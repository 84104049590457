/* StepsToFollow.css */

.steps-to-follow {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

#steps-container {
    list-style-type: decimal;
    margin-left: 20px;
}

#steps-container li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.5;
}
