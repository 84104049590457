.commodity-information {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 0 auto;
    padding: 20px;
    max-width: 1500px;
}

.commodity-information h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.commodity-information table {
    width: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.commodity-information th, td {
    text-align: center;
    padding: 3px;
    font-size: 12px;
    max-width: 150px;
    border-bottom: 1px solid #e0e0e0;
}

.commodity-information th {
    color: #757575;
}

.commodity-information td input, td select {
    font-size: 12px;
    padding: 6px 8px;
    margin: 4px 0;
    max-width: 90%;
    box-sizing: border-box;
    border: 1px solid #2E7D32;
    border-radius: 4px;
}

.commodity-information button {
    background-color: #2E7D32;
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.commodity-information button:hover {
    background-color: #2E7D32;
}

.commodity-information button:active {
    background-color: #2E7D32;
}
