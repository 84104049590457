.tables-container {
  font-family: "Poppins", sans-serif;
  display: grid;
  gap: 20px;
}

.table-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.table-wrapper h3 {
  margin: 2;
  padding: 1;
  text-align: center;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper table th {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 5px;
}

@media (min-width: 768px) {
  .tables-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .tables-container {
    grid-template-columns: 1fr;
  }
}
