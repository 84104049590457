.field-tiles-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  font-family: "Poppins", sans-serif;
}

.field-tile {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}

.field-tile:hover {
  transform: translateY(-4px);
}

.field-link {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.field-link:hover {
  color: #007bff;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #007bff;
}

.search-container {
  margin-bottom: 10px;
}

.search-container input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  position: relative;
  overflow-y: auto;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.popup-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.popup-actions button:hover {
  background-color: #0056b3;
}

.comments-section {
  margin-top: 20px;
}

.comments-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.comments-section button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.comments-section button:hover {
  background-color: #0056b3;
}

.download-button {
  position: absolute;
  top: 10px;
  right: 50px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: #007bff;
}

.download-button:hover::after {
  content: "Download as CSV";
  position: absolute;
  top: -20px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.sensor-present {
  color: rgb(119, 14, 184);
}

.sensor-not-present {
  color: red;
}

.sensor-fetching {
  color: black;
}

.exclusion-status {
  margin: 15px 0;
}

.exclusion-status label {
  font-weight: bold;
}
