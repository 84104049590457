body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-container {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 80%;
    max-width: 400px;
    margin-left: -100%; 
    transition: margin-left 0.5s ease-in-out;
}

h2 {
    color: #333;
    font-family: 'Times New Roman', Times, serif;
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 20px;
}

.grower-portal {
    font-family: 'Times New Roman', Times, serif;
    font-size: 22px;
    color: #2E7D32;
    margin-bottom: 10px;
}

.rower {
    font-size: 15px;
}

input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
}

.forgot-password {
    color: #3498db;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    display: inline-block;
}

.powered-by {
    color: #777;
    margin-top: 20px;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.logo {
    margin: 0 10px;
}

.login-container.visible {
    margin-left: 0;
}
