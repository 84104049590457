body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

/* #report-container {
    width: 80%;
    max-width: auto;
    padding: 20px;
    border: 2px solid #2E7D32;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
} */

h1 {
    color: #2E7D32;
}

button {
    margin-top: 15px;
    padding: 10px;
    background-color: #2E7D32;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

select {
    margin-top: 10px;
    padding: 8px;
}
