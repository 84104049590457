/* General Styles for the Form Screen */
.form-screen {
  max-width: 800px;
  margin: 20px auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif; /* Use your preferred font-family */
}

.form-screen h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Styles for the Container */
.container {
  margin-top: 20px;
}

/* Styles for the Table */
#farmTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#farmTable th,
#farmTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#farmTable th {
  background-color: #f4f4f4;
  color: #333;
}

/* Styles for Input Fields and Dropdown */
.input-field,
select {
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 192.13px !important; /* Set the width */
  height: 27.25px !important; /* Set the height */
  border-radius: 5px; /* Rounded corners */
}

.input-field[readonly] {
  background-color: #e9e9e9;
}

/* Styles for Icons (Add and Remove) */
svg {
  width: 24px;
  height: 24px;
}

/* Styles for Buttons */
button {
  padding: 8px 15px;
  background-color: #2e7d32;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #276629;
}

.disabled-button {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

/* Styles for Navigation Buttons */
.navigation-buttons {
  text-align: right;
  margin-top: 20px;
}

th {
  text-align: center;
  font-size: 14px;
}

.abc {
  text-align: center;
  font-size: 14px;
  padding: 5px 6px;
}
