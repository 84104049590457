body {
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure the page fills at least the viewport height */
}

#menu-container {
    font-family: 'Poppins', sans-serif;
    position: fixed;
    top: 0;
    left: 0; /* Menu slides in from the left */
    width: 20%;
    height: 100%;
    background-color: white;
    color: #2E7D32; /* Green color for text in the menu bar */
    transform: translateX(-100%); /* Start off-screen to the left */
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    padding-top: 20px; /* Added padding at the top */
}

#menu-content {
    padding: 20px;
}

#menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
#menu-content li {
    padding: 8px 0; /* Adjust padding as needed */
}

#menu-button {
    position: fixed;
    top: 20px;
    left: 20px; /* Positioned on the left side for easy access */
    z-index: 3; /* Ensure it's above the menu container */
    cursor: pointer;
    width: 40px; /* Width of the menu button */
    height: 25px; /* Height of the menu button */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.3s ease-in-out;
}

#menu-button span, .menu-bar {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #2E7D32;
    transition: all 0.3s ease-in-out;
}

#menu-container.open {
    transform: translateX(0); /* Slides in from the left */
}

h1 {
    margin-top: 20px;
    color: #2E7D32;
}

#menu-container a {
    display: block;
    color: #2E7D32;
    padding: 10px;
    text-decoration: none;
    transition: background-color 0.3s;
}

#menu-container a:hover {
    background-color: #2E7D32;
    color: #fff;
}

/* Transform spans into a cross when the menu is open */
#menu-button.open .menu-bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}
  
#menu-button.open .menu-bar:nth-child(2) {
    opacity: 0;
}
  
#menu-button.open .menu-bar:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
}

/* Media Queries for Mobile Compatibility */
@media (max-width: 768px) {
    #menu-container {
        width: 75%; /* Adjust menu width for smaller screens */
    }

    #menu-content li {
        padding: 12px 0; /* Larger padding for easier tap targets */
    }
}
