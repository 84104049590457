body {
    font: 1em sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150vh; /* Ensure the page fills at least the viewport height */
}

.signup-container {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%; /* Adjust the width as needed */
    max-width: 400px; /* Set a maximum width if desired */
}

h2 {
    color: #2E7D32; /* Darker text color */
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
}

label {
    font-size: small;
}

input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc; /* Add border for all input fields */
}

button {
    width: 100%;
    padding: 10px;
    background-color: #2E7D32; /* Darker green background color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.error-message {
    color: rgb(149, 38, 38);
    font-size: 11px;
    margin-top: 5px;
    display: block; /* Display error messages */
}

input.error {
    border-color: red;
}

.back-to-login {
    color: #3498db;
    margin-top: 10px;
    cursor: pointer;
}

span {
    color: red;
}

.date-picker-input {
    width: 100%; /* Adjust the width as needed */
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
}




