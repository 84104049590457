.review-container {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-top: 40px; /* Add 40px margin to the top of the review container */
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px;
    max-width: 1500px;
}

.review-container h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.review-container table {
    width: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.review-container th, td {
    border: 1px solid #2E7D32;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    max-width: 150px;
    border-bottom: 1px solid #e0e0e0;
}

.review-container th {
    color: #757575;
}

.review-container td input, td select {
    font-size: 12px;
    padding: 6px 8px;
    margin: 4px 0;
    max-width: 90%;
    box-sizing: border-box;
    border: 1px solid #2E7D32;
    border-radius: 4px;
}


.review-container button {
    background-color: #2E7D32;
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.review-container button:hover {
    background-color: #2E7D32;
}

.commodity-information button:active {
    background-color: #2E7D32;
}
