/* FieldDashboard Styles with Class Selectors */

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-header, .table-row, .table-cell {
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.table-header {
  background-color: #f5f5f5;
  color: #333;
  font-weight: 500;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.link {
  color: #1976d2;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: #0d47a1;
}

.dashboard-container {
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  max-width: 960px;
  margin: 20px auto;
  background-color: white;
}
