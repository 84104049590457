body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

h1 {
    margin-top: 20px;
    color: #2E7D32;
}

#sensor-data-container {
    margin-top: 20px;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border: 2px solid #2E7D32;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sensor-attribute {
    font-weight: bold;
    margin-bottom: 10px;
}
