@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

:root {
    --primary-color: #2E7D32; /* Green color */
    --background-color: #f4f4f4; /* Light grey */
    --text-color: #333; /* Dark grey for text */
    --container-bg-color: #fff; /* White */
    --border-color: #2E7D32; /* Green, same as primary color */
    --shadow-color: rgba(0, 0, 0, 0.1); /* Shadow color */
}

body {
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    min-height: 100vh;
    color: var(--text-color);
}

#user-info-container {
    font-family: 'Poppins', sans-serif;
    display: flex; /* Make the container a flexbox */
    flex-wrap: wrap; /* Allow items to wrap as needed */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically if there's more than one line */
    gap: 20px; /* Add some space between the blocks */
    width: 80%; /* Adjust width as needed */
    max-width: 1200px; /* Adjust max width as needed */
    padding: 20px; /* Add padding to the container */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.info-block {
    font-family: 'Poppins', sans-serif;
    flex: 1; /* Each block will grow to fit the container */
    min-width: 250px; /* Minimum width for each block to ensure it remains readable */
    max-width: 300px; /* Maximum width to prevent blocks from growing too large */
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    background-color: var(--container-bg-color);
    box-shadow: 0 4px 6px var(--shadow-color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-block:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px var(--shadow-color);
}

.user-attribute {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: var(--text-color);
}

h1 {
    color: var(--primary-color);
    margin-bottom: 0.5em;
    text-align: center; /* Center the heading text */
    width: 100%; /* Ensure the title spans the full width of its container */
}

p {
    margin-top: 10px;
}